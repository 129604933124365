import {isEventRsvpButtonVisible, isExternal} from '@wix/wix-events-commons-statics'
import {MouseEventHandler} from 'react'
import {getEventDetailsPageUrl, getEventUrl, isEventRestricted} from '../../selectors/events'
import {AppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'
import {LinkToPageOwnProps, LinkToPageStateProps} from './interface'
import {LinkToPage as LinkToPagePresentation} from './link-to-page'

const mapRuntime = (
  {state, actions, baseUrl, pageUrl, queryParams}: AppProps,
  {event, hasAdditionalInfoToggle, allowExternal}: LinkToPageOwnProps,
): LinkToPageStateProps => {
  const isRestricted = isEventRestricted(state, event)
  const url = allowExternal
    ? getEventUrl(state, event, baseUrl, pageUrl?.relativeUrl, queryParams)
    : getEventDetailsPageUrl(state, event, baseUrl, pageUrl?.relativeUrl, queryParams)
  let onClick: MouseEventHandler
  if (hasAdditionalInfoToggle) {
    onClick = e => e.preventDefault()
  } else if (url) {
    onClick = _e => actions.onLinkNavigatedToPage(event)
  } else {
    onClick = e => {
      if (isRestricted) {
        e.stopPropagation()
      }
      actions.navigateToPage(event)
    }
  }

  return {
    url,
    isExternalUrl: allowExternal && isExternal(event),
    onClick,
    disabled: (hasAdditionalInfoToggle && !url) || !isEventRsvpButtonVisible(event),
  }
}

export const LinkToPage = connect<LinkToPageOwnProps, LinkToPageStateProps>(mapRuntime)(LinkToPagePresentation)
